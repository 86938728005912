import { mdiChartBar, mdiLogout, mdiApplicationEdit, mdiCarPickup, mdiDropbox, mdiBarcodeScan, mdiTruck, mdiAccountArrowLeft } from '@mdi/js';

export const menuList = [
    {
        id: 850,
        iconName: 'chart-bar',
        text: 'Yeni Gönderi oluştur',
        route: 'Shipment',
        icon: mdiChartBar,
        isPermitted: false,
    },
    {
        id: 2,
        iconName: 'car-pickup',
        text: 'TOPLAMA ',
        route: '/collection-info',
        icon: mdiCarPickup,
        isPermitted: false,
    },
    {
        id: 3,
        iconName: 'dropbox',
        text: 'DAĞITIM',
        route: '/distribution',
        isPermitted: false,
        icon: mdiDropbox
    },
    {
        id: 837,
        iconName: 'barcode-scan',
        text: 'SİPARİŞE BAĞLI GÖNDERİ OLUŞTUR',
        route: '/collection',
        isPermitted: false,
        icon: mdiBarcodeScan
    },
    {
        id: 838,
        iconName: 'car-pickup',
        text: 'GÖNDERİ OLUŞTUR',
        route: 'CreateOrderWithoutExpertise',
        isPermitted: false,
        icon: mdiCarPickup

    },
    {
        id: 839,
        iconName: 'truck',
        text: 'YÜKLEME',
        route: 'load-unload',
        isPermitted: false,
        icon: mdiTruck

    },
    {
        id: 836,
        iconName: 'account-arrow-left',
        text: 'ZİMMET',
        route: '/embezzlement',
        isPermitted: false,
        icon: mdiAccountArrowLeft
    },
    {
        id: 877,
        iconName: 'application-edit',
        text: 'Expertize',
        route: '/expertize',
        isPermitted: false,
        icon: mdiApplicationEdit
    },
    {
        id: 880,
        iconName: 'logout',
        text: 'ÇIKIŞ',
        route: 'SignOut',
        isPermitted: false,
        icon: mdiLogout
    },
];

export const PERMISSIONITEM = {
    GATHERING_QUESTS: {
        "code": "GATHERING_QUESTS",
        "id": 832,
        "moduleName": "Mobil Yetkiler",
        "name": "Toplama Görevlerim Listeleme",
        "url": "Mobile",
    },
    CREATINGSHIPMENTBARCODERECEIVING_ONEBYONE: {
        "code": "CREATINGSHIPMENTBARCODERECEIVING_ONEBYONE",
        "id": 851,
        "moduleName": "Mobil Yetkiler",
        "name": "Tek Tek Teslim Alarak Barkodla Gönderi Oluşturma",
        "url": "Mobile"
    },
    CREATINGDELIVERYTAKINGPURCHASERECORDORDER_ONEBYONE: {
        "code": "CREATINGDELIVERYTAKINGPURCHASERECORDORDER_ONEBYONE",
        "id": 852,
        "moduleName": "Mobil Yetkiler",
        "name": "Tek Tek Teslim Alarak Alım Tutanak İle Gönderi Oluşturma",
        "url": "Mobile",
    },
    RECEIVINGNOTICE_CANCELINGORDER: {
        "code": "RECEIVINGNOTICE_CANCELINGORDER",
        "id": 853,
        "moduleName": "Mobil Yetkiler",
        "name": "Alım İhbarını / Siparişi İptal Etme",
        "url": "Mobile",
    },
    CREATINGSHIPMENTBARCODETAKINGBULKDELIVERY: {
        "code": "CREATINGSHIPMENTBARCODETAKINGBULKDELIVERY",
        "id": 854,
        "moduleName": "Mobil Yetkiler",
        "name": "Toplu Teslim Alarak Barkodla Gönderi Oluşturma",
        "url": "Mobile",
    },
    I_COLLECTED: {
        "code": "I_COLLECTED",
        "id": 833,
        "moduleName": "Mobil Yetkiler",
        "name": "Topladıklarım Listeleme",
        "url": "Mobile",
    },
    DEPLOYMENT_TASKS: {
        "code": "DEPLOYMENT_TASKS",
        "id": 834,
        "moduleName": "Mobil Yetkiler",
        "name": "Dağıtım Görevlerim Listeleme",
        "url": "Mobile",
    },
    ONEBYONEGIVE_DELIVERY: {
        "code": "ONEBYONEGIVE_DELIVERY",
        "id": 855,
        "moduleName": "Mobil Yetkiler",
        "name": "Tek Tek Teslim Etme",
        "url": "Mobile",
    },
    BULK_DELIVERY: {
        "code": "BULK_DELIVERY",
        "id": 856,
        "moduleName": "Mobil Yetkiler",
        "name": "Toplu Teslim Etme",
        "url": "Mobile",
    },
    RECIPIENT_TCKNO_TMANDATORY: {
        "code": "RECIPIENT_TCKNO_TMANDATORY",
        "id": 857,
        "moduleName": "Mobil Yetkiler",
        "name": "Teslim Alan TCKN Zorunlu Değil",
        "url": "Mobile",
    },
    DELIVERYRECIPIENTPASSPORTNUMBERNOTMANDATORY: {
        "code": "DELIVERYRECIPIENTPASSPORTNUMBERNOTMANDATORY",
        "id": 858,
        "moduleName": "Mobil Yetkiler",
        "name": "Teslim Alan Pasaport No Zorunlu Değil",
        "url": "Mobile",
    },
    RECIPIENTCONTACTLESSDELIVERYCODENOTREQUIRED: {
        "code": "RECIPIENTCONTACTLESSDELIVERYCODENOTREQUIRED",
        "id": 858,
        "moduleName": "Mobil Yetkiler",
        "name": "Teslim Alan Temassız Teslimat Kodu Zorunlu Değil",
        "url": "Mobile",
    },
    RECIPIENTPROXIMITYNOTREQUIRED: {
        "code": "RECIPIENTPROXIMITYNOTREQUIRED",
        "id": 859,
        "moduleName": "Mobil Yetkiler",
        "name": "Teslim Alan Yakınlık Derecesi Zorunlu Değil",
        "url": "Mobile",
    },
    RECEIVINGDISCLOSURENOTMANDATORY: {
        "code": "RECEIVINGDISCLOSURENOTMANDATORY",
        "id": 860,
        "moduleName": "Mobil Yetkiler",
        "name": "Teslim Alan Açıklama Zorunlu Değil",
        "url": "Mobile",
    },
    REASONRETURNNOTMANDATORY: {
        "code": "REASONRETURNNOTMANDATORY",
        "id": 861,
        "moduleName": "Mobil Yetkiler",
        "name": "İade Sebebi Zorunlu Değil",
        "url": "Mobile",
    },
    RETURNDESCRIPTIONNOTMANDATORY: {
        "code": "RETURNDESCRIPTIONNOTMANDATORY",
        "id": 862,
        "moduleName": "Mobil Yetkiler",
        "name": "İade Açıklama Zorunlu Değil",
        "url": "Mobile",
    },
    RETURNCONTACTLESSDELIVERYCODENOTMANDATORY: {
        "code": "RETURNCONTACTLESSDELIVERYCODENOTMANDATORY",
        "id": 863,
        "moduleName": "Mobil Yetkiler",
        "name": "İade Temassız Teslimat Kodu Zorunlu Değil",
        "url": "Mobile",
    },
    PARTIALDELIVERY_RETURNTCKN_NOTMANDATORY: {
        "code": "PARTIALDELIVERY_RETURNTCKN_NOTMANDATORY",
        "id": 864,
        "moduleName": "Mobil Yetkiler",
        "name": "Kısmi Teslim / İade TCKN Zorunlu Değil",
        "url": "Mobile",
    },
    PARTIALDELIVERYRETURNPASSPORTNUMBERNOTMANDATORY: {
        "code": "PARTIALDELIVERYRETURNPASSPORTNUMBERNOTMANDATORY",
        "id": 865,
        "moduleName": "Mobil Yetkiler",
        "name": "Kısmi Teslim / İade Pasaport No Zorunlu Değil",
        "url": "Mobile",
    },
    PARTIALDELIVERYRETURNCONTACTLESSDELIVERYCODENOTMANDATORY: {
        "code": "PARTIALDELIVERYRETURNCONTACTLESSDELIVERYCODENOTMANDATORY",
        "id": 866,
        "moduleName": "Mobil Yetkiler",
        "name": "Kısmi Teslim / İade Temassız Teslimat Kodu Zorunlu Değil",
        "url": "Mobile",
    },
    PARTIALDELIVERYRETURNPROXIMITYNOTMANDATORY: {
        "code": "PARTIALDELIVERYRETURNPROXIMITYNOTMANDATORY",
        "id": 867,
        "moduleName": "Mobil Yetkiler",
        "name": "Kısmi Teslim / İade Yakınlık Derecesi Zorunlu Değil",
        "url": "Mobile",
    },
    PARTIALDELIVERYREASONREFUNDNOTREQUIRED: {
        "code": "PARTIALDELIVERYREASONREFUNDNOTREQUIRED",
        "id": 868,
        "moduleName": "Mobil Yetkiler",
        "name": "Kısmi Teslim / İade Sebebi Zorunlu Değil",
        "url": "Mobile",
    },
    PARTIALDELIVERYRETURNDISCLOSURENOTMANDATORY: {
        "code": "PARTIALDELIVERYRETURNDISCLOSURENOTMANDATORY",
        "id": 869,
        "moduleName": "Mobil Yetkiler",
        "name": "Kısmi Teslim/İade Açıklama Zorunlu Değil",
        "url": "Mobile",
    },
    I_DISTRIBUTED: {
        "code": "I_DISTRIBUTED",
        "id": 835,
        "moduleName": "Mobil Yetkiler",
        "name": "Dağıttıklarım Listeleme",
        "url": "Mobile",
    },

    DEBITINGONEBYONE: {
        "code": "DEBITINGONEBYONE",
        "id": 875,
        "moduleName": "Mobil Yetkiler",
        "name": "Tek Tek Zimmet Alma",
        "url": "Mobile",
    },
    COLLECTIVEDEBITTAKE: {
        "code": "COLLECTIVEDEBITTAKE",
        "id": 876,
        "moduleName": "Mobil Yetkiler",
        "name": "Toplu Zimmet Alma",
        "url": "Mobile",
    },

    DOINGEXPERTISEONEBYONE: {
        "code": "DOINGEXPERTISEONEBYONE",
        "id": 878,
        "moduleName": "Mobil Yetkiler",
        "name": "Tek Tek Expertiz Yapma",
        "url": "Mobile",
    },
    DOINGEXPERTISECOLLECTIVE: {
        "code": "DOINGEXPERTISECOLLECTIVE",
        "id": 879,
        "moduleName": "Mobil Yetkiler",
        "name": "Toplu Expertiz Yapma",
        "url": "Mobile",
    },
    ORDER_LINKED_SHIPMENT_CREATE: {
        "code": "ORDER_LINKED_SHIPMENT_CREATE",
        "id": 837,
        "moduleName": "Mobil Yetkiler",
        "name": "Siparişe bağlı gönderi oluşturma",
        "url": "Mobile",
    },
    POST_CREATE: {
        "code": "POST_CREATE",
        "id": 838,
        "moduleName": "Mobil Yetkiler",
        "name": "Gönderi oluştur",
        "url": "Mobile",
    },

    DOWNLOADINGINDEPENDENTBRANCH: {
        "code": "DOWNLOADINGINDEPENDENTBRANCH",
        "id": 870,
        "moduleName": "Mobil Yetkiler",
        "name": "Seferden Bağımsız Şubeye İndirme",
        "url": "Mobile",
    },
    EXPEDITIONINDEPENDENTEYEDOWNLOAD: {
        "code": "EXPEDITIONINDEPENDENTEYEDOWNLOAD",
        "id": 871,
        "moduleName": "Mobil Yetkiler",
        "name": "Seferden Bağımsız Göze İndirme",
        "url": "Mobile",
    },
    EXPEDITIONBASEDLOADING: {
        "code": "EXPEDITIONBASEDLOADING",
        "id": 872,
        "moduleName": "Mobil Yetkiler",
        "name": "Sefere Bağlı Yükleme",
        "url": "Mobile",
    },
    EXPEDITIONBASEDDOWNLOAD: {
        "code": "EXPEDITIONBASEDDOWNLOAD",
        "id": 873,
        "moduleName": "Mobil Yetkiler",
        "name": "Sefere Bağlı İndirme",
        "url": "Mobile",
    },
    BELOW_DELIVERY_PROBABILITY: {
        "code": "BELOW_DELIVERY_PROBABILITY",
        "id": 840,
        "moduleName": "Mobil Yetkiler",
        "name": "Teslim seçeneğinin altında",
        "url": "Mobile",
    },
    TCKN: {
        "code": "TCKN",
        "id": 841,
        "moduleName": "Mobil Yetkiler",
        "name": "TCKN",
        "url": "Mobile",
    },
    PASSPORT_NUMBER: {
        "code": "PASSPORT_NUMBER",
        "id": 842,
        "moduleName": "Mobil Yetkiler",
        "name": "Pasaport No",
        "url": "Mobile",
    },
}
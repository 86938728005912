export const openSideMenuAction = () => {
  return {
    type: "OPEN_SIDE_MENU",
  };
};


export const closeSideMenuAction = () => {
  return {
    type: "CLOSE_SIDE_MENU",
  };
};


export const showLoading = () => {
  return {
    type: "SHOW_LOADING",
  };
};


export const hideLoading = () => {
  return {
    type: "HIDE_LOADING",
  };
};

export const loginAction = () => {
  return {
    type: "LOGIN",
  };
};

export const logoutAction = () => {
  return {
    type: "LOGOUT",
  };
};

export const setMessagingAction = (payload:any) => {
  return {
    type: "SET_MESSAGING",
    payload
  };
};

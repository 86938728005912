

export interface ISettings {
    baseUrl: string;
    authUrl: string;
    serviceUrl: string;
}

export const settings = (): ISettings => {

    // const baseUrl: string = 'https://globalbilge.com/api/';
    // const authUrl: string = 'https://globalbilge.com/connect/token';
    // // const serviceUrl: string = 'https://api.igc.network/api/'
    // const serviceUrl: string = 'https://api.globalbilge.com/api/'

    const baseUrl: any = process.env.REACT_APP_BASE_URL;
    const authUrl: any = process.env.REACT_APP_AUTH_URL;
    const serviceUrl: any = process.env.REACT_APP_SERVICE_URL;


    return {
        baseUrl,
        authUrl,
        serviceUrl
    }

}

export async function createHeaders(isFormData?: boolean): Promise<any> {
    let headers = {} as any;
    //headers['X-IgcMobile-DeviceId'] = settings.deviceId;
    headers['Content-Type'] = isFormData ? 'multipart/form-data' : 'application/json; charset=utf-8';
    let token = localStorage.getItem('token');
    if (token != null) { headers['Authorization'] = 'Bearer ' + token; }
    return headers;
}

export const getCoords = async (): Promise<any> => {
    const pos: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    return {
        long: pos.coords.longitude,
        lat: pos.coords.latitude,
    };
};
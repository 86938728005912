import React, { useEffect, useRef } from 'react';

import { AkbariSideMenu } from 'akbari-side-menu/dist/index';
import 'akbari-side-menu/dist/index.css';

import SideMenu from './../../components/side-menu/side-menu';

const Home = () => {

    const sideRef = useRef<any>(null)

    useEffect(() => {
        sideRef.current?.open();
    }, [])



    return (
        <>

            <AkbariSideMenu
                ref={sideRef}
                pcWidth={50}
                mobileWidth={80}
                direction={'ltr'}
                showCloseButton={false}
            >

                <SideMenu />


            </AkbariSideMenu>



        </>
    )
}

export default Home;
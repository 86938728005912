import {useEffect, useState, useRef} from 'react';
import styles from './header.module.scss'
import { openSideMenuAction } from '../../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiBell } from '@mdi/js';
import {onMessage} from 'firebase/messaging';
declare var bootstrap: any;

const Header = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const messaging = useSelector((store: any) => store.fcmReducer.messaging)
    const [notifications, setNotifications]=useState<any>([]);
    const [notificationsLength, setNotificationsLength]=useState<number>(0);

    const modalRef = useRef<any>();

    useEffect(() => {
        setTimeout(() => {
            modalRef.current = new bootstrap.Modal(document.getElementById('notifications-modal'), {
                keyboard: false
            })
        }, 100)
    }, [])

    useEffect(()=>{
        const notif=JSON.parse(localStorage.getItem('notif') ?? '[]')
        if(notif){
            setNotifications(notif)
            setNotificationsLength(notif?.filter(item=>!item?.isRead).length)
        }
    },[])

    onMessage(messaging, (payload) => {
        const notif=[{...payload?.notification, isRead:false}, ...notifications]
        updateNotifications(notif)
    });

    const updateNotifications=(notif)=>{
        setNotifications(notif)
        localStorage.setItem('notif',JSON.stringify(notif))
        setNotificationsLength(notif?.filter(item=>!item?.isRead).length)
    }

    const openSideMenu = () => {
        dispatch(openSideMenuAction());
    }

    const back = () => {
        navigate(-1)
    }

    const handleOpenModal = () => {
        if(notifications?.length){
            modalRef.current.show()
        }
    }

    const handleMarkAsRead=(index)=>{
            const notif=notifications.map((item,idx)=> idx===index?{...item, isRead:true}:item)
            updateNotifications(notif)
            setNotificationsLength(notif?.filter(item=>!item?.isRead).length)
    }

    return (
        <div className={styles.header} >
            <div className={styles.icons}>
                <i className='fa fa-angle-left' onClick={back}> </i>
                <i className="fa-regular fa-bars-staggered fa-lg" onClick={openSideMenu}></i>
            </div>
            <span>
            {props.title}
            </span>
            <span className={styles.notifIcon} onClick={handleOpenModal}>
                <Icon path={mdiBell} size={1} />
                {notificationsLength?
                    <span className="position-absolute translate-middle badge rounded-pill bg-danger">
                        {notificationsLength}
                    </span>
                    :null
                }
            </span>

            <div className="modal fade " id="notifications-modal" role="dialog">
                <div className={`modal-dialog modal-dialog-centered ${styles.modalDialog}`} role="document">
                    <div className={`modal-content ${styles.modalContent}`}>
                        <div className="modal-header">
                            <h5 className={`modal-title ${styles.modalTitle}`}>Notifications</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className={`modal-body ${styles.modalBody}`}>
                            <div className="accordion" id="notificationsAccordion">
                                {
                                    notifications?.map((item,index)=>
                                        <div key={`${item?.title}_${index}`} className="accordion-item">
                                            <h2 className="accordion-header" onClick={()=>handleMarkAsRead(index)}>
                                                <button className={`accordion-button collapsed ${item.isRead?'':styles.itemTitle}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                    {item.title}
                                                </button>
                                            </h2>
                                            <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#notificationsAccordion">
                                                <div className={`accordion-body ${styles.itemDescription}`}>
                                                    <span>{item.body}</span>
                                                    {item.image && <img className={styles.itemImage} alt='' src={item.image}/>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
import React, { useEffect, useRef, useState } from 'react';
import styles from './distribution-items.module.scss';
import { IDistributionList } from '../../../interfaces/distribution-list.interface';
import moment from 'moment'
import { orderStatusCodeAndName } from '../../../common/student-contstants';
import { IDelivery } from '../../../interfaces/delivery-params.interface';
import { Navigate, useNavigate } from 'react-router-dom';
import { handOver } from '../../../services/distribution.service';
import { toast } from 'react-toastify';



type props = {
    list: IDistributionList[],
    reload: (e: boolean) => void;
    onSelectedList: (e: Array<IDelivery>) => void;
}
declare var bootstrap: any;
const DistributionItems = (props: props) => {

    const [list, setList] = useState<IDistributionList[]>([]);
    const [selectedList, setSelectedList] = useState<IDelivery[]>([]);
    const [showActions, setShowActions] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<IDistributionList>({} as IDistributionList);
    const modalRef = useRef<any>();
    const navigate = useNavigate();

    useEffect(() => {
        setList([...props.list]);
    }, [props.list])

    useEffect(() => {
        setTimeout(() => {
            modalRef.current = new bootstrap.Modal(document.getElementById('devir'), {
                keyboard: false
            })
        }, 100)
    }, [])

    const toggleContent = (index: number) => {
        list[index].openContent = !list[index].openContent;
        setList([...list])
    }

    const onSelect = (item: IDistributionList) => {
        setShowActions(true);
        setSelectedItem({ ...item });
    }

    const setDeliveryData = () => {
        const deliveryItems: IDelivery[] = [];
        const data = {} as IDelivery;
        data.orderId = selectedItem.orderId;
        data.distributionPlanId = selectedItem.id;
        data.deliveredItemBarcodes = selectedItem.barcodes.map(item => item.no);
        deliveryItems.push(data)
        localStorage.setItem('delivery-items', JSON.stringify(deliveryItems));
        navigate('/delivery/distribution')

    }

    const setReturnData = () => {
        const data = {} as IDelivery;
        data.orderId = selectedItem.orderId;
        data.distributionPlanId = selectedItem.id;
        data.deliveredItemBarcodes = selectedItem.barcodes.map(item => item.id);
        localStorage.setItem('return-item', JSON.stringify(data));
        navigate('/return')
    }

    const openModal = () => {
        modalRef.current.show()
    }

    const closeModal = () => {
        modalRef.current.hide()
    }

    const devir = async () => {
        setLoader(true)
        const res = await handOver(selectedItem.id);
        setLoader(false)
        if (res.success === true) {
            toast.success('Devir işlemi başarıyla gerçekleşti.', {
                position: "bottom-center",
            });

        } else {
            toast.error(res.message, {
                position: "bottom-center",
            });
        }

        closeModal();
        setShowActions(false);
        props.reload(true);

    }


    const cancel = () => {
        setShowActions(false);
        closeModal();
    }

    const selectItems = (item: IDistributionList) => {
        const index = selectedList.findIndex(a => a.orderId === item.orderId);
        if (index === -1) {
            const data = {} as IDelivery;
            data.orderId = item.orderId;
            data.distributionPlanId = item.id;
            data.deliveredItemBarcodes = item.barcodes.map(item => item.id);
            selectedList.push(data);
            setSelectedList([...selectedList])
        }
        else {
            selectedList.splice(index, 1);
            setSelectedList([...selectedList])
        }
        //   localStorage.setItem('return-item', JSON.stringify(data));
    }

    const checkSelectedItems = (item) => {
        const index = selectedList.findIndex(a => a.orderId === item.orderId);
        return index > -1;
    }

    useEffect(() => {
        localStorage.setItem('delivery-items', JSON.stringify(selectedList));
        props.onSelectedList(selectedList)
    }, [selectedList])

    return (
        <>

            <div className={styles.items}>

                {list?.map((item, index) => {
                    return (
                        <div onClick={() => selectItems(item)} key={item.orderNumber} className={`${styles.item} box-item`}>


                            <div className={styles.itemTitle} onClick={() => toggleContent(index)}>
                                {/* {checkSelectedItems(item) ? (
                                    <i className='fa fa-check text-success me-3'></i>
                                ) : null} */}

                                <div className={styles.titleLeft}>
                                    <span>{item.orderNumber}</span>
                                    <span>{moment(item.distributionDate).format('DD/MM/YYYY')}</span>
                                    <span>{item.productName}</span>
                                    <span>
                                        Durum: {orderStatusCodeAndName.find(x => x.id == (item.orderStatus))?.text}

                                    </span>
                                </div>
                                <i className={`fa fa-angle-down fa-lg ${styles.angle}`} ></i>
                            </div>

                            <div className={`${styles.itemContent} ${item.openContent ? 'show' : styles.hide}`} >

                                <div className={styles.contentItem}>
                                    <div className={styles.key}>Gönderen</div>
                                    <div className={styles.value}>{item?.senderCustomerName}</div>
                                </div>

                                <div className={styles.contentItem}>
                                    <div className={styles.key}>Alıcı</div>
                                    <div className={styles.value}>{
                                        item.delivererCustomer != null
                                            ? item.delivererCustomer.customerNameAndCode
                                            : item.receiverCustomer.customerNameAndCode
                                    }
                                    </div>
                                </div>

                                <div className={styles.contentItem}>
                                    <div className={styles.key}>Alıcı Kişi</div>
                                    <div className={styles.value}>
                                        {
                                            item?.receiverEmployee?.employee?.absoluteNameAndCustomerCode
                                        }
                                    </div>
                                </div>

                                <div className={styles.contentItem}>
                                    <div className={styles.key}>Teslimat Adresi</div>
                                    <div className={styles.value}>
                                        {item?.receiverAddressName}
                                    </div>
                                </div>

                                <div className={styles.contentItem}>
                                    <div className={styles.key}>Mobil</div>
                                    <div className={styles.value}>
                                    <span>Mobil: {item?.receiverCustomer.mobilePhone ?? '___'}</span> 
                                    <span>/</span>
                                    <span>İş: {item?.receiverCustomer.workPhone ?? '___'}</span> 
                                    <span>/</span>
                                    <span>Ev: {item?.receiverCustomer.homePhone ?? '___'}</span> 
                                    </div>
                                </div>

                                <div className={styles.button} onClick={() => onSelect(item)}>
                                    <button className={`btn`}>İşlem</button>
                                </div>

                            </div>



                        </div>
                    )
                })}

            </div>

            {showActions ? (
                <>
                    <div className={styles.backDrop} onClick={() => setShowActions(false)}></div>

                    <div className={styles.actions}>
                        <div className={styles.actionTitle}>Yapmak istediğiniz işlemi seçiniz</div>
                        <div className={styles.actionItem} onClick={setDeliveryData}>
                            <img src="./assets/images/tick.svg" alt='Teslim1' />
                            <span>Teslim1</span>
                        </div>

                        <div className={styles.actionItem} onClick={setReturnData}>
                            <img src="./assets/images/refresh.svg" alt='refresh' />
                            <span>İade</span>
                        </div>

                        <div className={styles.actionItem} onClick={openModal}>
                            <img src="./assets/images/aperture.svg" alt='refresh' />
                            <span>Devir</span>
                        </div>

                        <div className={styles.actionItem} onClick={cancel}>
                            <img src="./assets/images/close.svg" alt='refresh' />
                            <span>İptal</span>
                        </div>

                    </div>
                </>

            ) : null}


            <div className="modal fade " id="devir" role="dialog">
                <div className="modal-dialog modal-dialog-centered " role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className={styles.modalTitle}>
                                Uyarı
                            </div>
                            <div className={styles.modalBody}>
                                Bu gönderiyi devir etmek istediğinizden emin misiniz?
                            </div>
                            <div className={styles.modalButtons}>
                                <button className='pretty-btn' data-bs-dismiss="modal" >Hayır</button>
                                <button className='pretty-btn' onClick={devir}>
                                    Evet
                                    {loader ? (
                                        <div className='spinner-border text-danger'></div>
                                    ) : null}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>







        </>
    )

}

export default DistributionItems;
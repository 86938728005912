import React, { useEffect, useRef, useState } from 'react';
import styles from './side-menu.module.scss';
import { PERMISSIONITEM, menuList } from '../../common/menu';
import { getPermissions } from '../../services/global.service';
import Icon from '@mdi/react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../store/actions';
import { useSelector } from 'react-redux';


const SideMenu = () => {

    const permissions = useRef<any>([]);
    const [menus, setMenus] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);
    const [user, setUser] = useState<any>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginStatus = useSelector((a: any) => a.loginStatus);


    useEffect(() => {
        getPermitedMenus();
        getUserInfo();
    }, [])


    useEffect(() => {
        if (loginStatus) {
            getPermitedMenus();
            getUserInfo();
        }
    }, [loginStatus])

    const getUserInfo = () => {
        let info: any = localStorage.getItem('user');
        if (info) {
            info = JSON.parse(info);
            setUser({ ...info })
        }

    }

    const getPermitedMenus = async () => {
        const res = await getPermissions();
        permissions.current = res.data.data;
        setMenus([...menuList])
        setLoader(false)
    }


    const checkPermission = (id: number) => {
        let permit = false
        if (id === 2) {
            let gorevlerim = permitted(PERMISSIONITEM?.GATHERING_QUESTS?.id)
            let dagittiklarm = permitted(PERMISSIONITEM?.I_COLLECTED?.id)
            permit = gorevlerim || dagittiklarm
        }

        else if (id === 3) {
            let gorevlerim = permitted(PERMISSIONITEM?.DEPLOYMENT_TASKS?.id)
            let dagittiklarm = permitted(PERMISSIONITEM?.I_DISTRIBUTED?.id);
            permit = gorevlerim || dagittiklarm
        }

        else permit = permissions.current?.find((item) => item?.id === id) ? true : false

        return permit

    }

    const permitted = (value: any) => {

        return permissions.current?.find((item) => item?.id == value) ? true : false

    }


    const route = (item) => {
        if (item.iconName === 'logout') {
            logout();
        }
        else {
            navigate(item.route)
        }
    }


    const logout = () => {
        navigator.serviceWorker.getRegistrations().then((r) => {
            return Promise.all(r.map(reg => {
                if ((reg.active && reg.active.scriptURL.includes("firebase-messaging"))) {
                    reg.unregister()
                }
            }));
        });
        caches.keys().then((names) => {
            names.forEach(async (name) => {
                await caches.delete(name)
            })
        })
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        dispatch(logoutAction())
        window.location.href = '/login'
    }




    return (
        <>

            <div className={styles.side}>
                <div className={styles.info}>
                    <span>{user?.given_name} {user?.family_name}</span>
                    <span className={styles.branch}>{user?.ActiveBranchName}</span>
                    <i onClick={logout} className="fa-solid fa-right-from-bracket"></i>
                </div>

                <div className={styles.items}>
                    {!loader ? menus?.map(item => {

                        if (!checkPermission(item.id)) {
                            return
                        }

                        return (
                            <div onClick={() => route(item)} className={styles.item} key={item.text}>
                                <div className={styles.icon}>
                                    <Icon path={item.icon}
                                        title={item.icon}
                                        size={1}
                                        horizontal
                                        vertical
                                        rotate={180}
                                        color="black"
                                    // spin
                                    />
                                </div>
                                <div className={styles.text}>{item.text}</div>
                            </div>
                        )
                    }) : null}

                </div>

            </div>

        </>
    )
}

export default SideMenu;
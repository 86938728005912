import React, { useEffect, useRef, useState } from 'react';
import styles from './delivery-image.module.scss';
import Camera from '../../../components/camera/camera';
import SignatureCanvas from 'react-signature-canvas'
import axios from './../../../common/axios';
import { createHeaders, settings } from '../../../common/utils';

const DeliveryImage = (props) => {

    const [openCamera, setOpenCamera] = useState<any>(null);
    const [imageList, setImageList] = useState<Array<any>>([]);
    const [imageIds, setImageIds] = useState<Array<string>>([]);

    const [signSrc, setSignSrc] = useState('');
    const sigPad = useRef<any>();
    const [selectedFile, setSelectedFile] = useState<any>(null);


    const trimSign = () => {
        const src = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
        addToImageList(src);
        setSignSrc(src);
    }

    const addToImageList = async (base64) => {
        const data = {} as any;
        data.image = base64;
        imageList.push(data)
        setImageList([...imageList])
        setOpenCamera(null);
        const image = await dataUrlToFile(base64, 'test');
        upload(image, imageList.length)
    }

    const removeImage = (index: number) => {
        imageList.splice(index, 1)
        setImageList([...imageList])
    }

    const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }


    const upload = async (file, index) => {
        const formData = new FormData();
        formData.append("File", file);
        let headers = await createHeaders(true);
        let token = localStorage.getItem('token');
        let url: string = settings().serviceUrl + "file";
        //  let url: string = 'http://95.0.103.251:81/api/' + "file";
        const res = await axios.post(url, formData, {
            onUploadProgress: (progressEvent: any) => {
                imageList[index - 1].precent = (progressEvent.loaded / progressEvent.total) * 100;
                setImageList([...imageList])
            },
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": 'multipart/form-data',
                "accept": 'application/json',
            }
        })


        if (res.data.data) {
            imageIds.push(res.data.data)
            setImageIds([...imageIds])
        }


    }


    useEffect(() => {
        props.onImageUpload(imageIds)
    }, [imageIds])



    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }

    return (
        <>
            <div className={styles.file}>
                <div className={styles.title}>
                    {props.title}
                    <div className={styles.buttons}>
                        {props.hasSignature ? (
                            <button className='pretty-btn btn-outline btn-sm' type='button' onClick={() => setOpenCamera(false)} >Manuel</button>
                        ) : null}
                        <button className='pretty-btn btn-sm' type='button' onClick={() => setOpenCamera(true)}>Fotoğraf</button>
                    </div>
                </div>
                <div className={`${styles.input} ${styles.sign}`}>

                    {openCamera == true ? (
                        <Camera onCapture={e => addToImageList(e)} />
                    ) : null}

                    {openCamera == false ? (

                        <div className={styles.signature}>

                            <SignatureCanvas canvasProps={{ className: styles.sigPad }} ref={sigPad} />

                            <button className='pretty-btn btn-primary' type='button' onClick={() => { setOpenCamera(null); trimSign() }}>Kayıt</button>

                        </div>

                    ) : null}


                    {openCamera == null ? (
                        <img src={props.signSrc} />
                    ) : null}


                </div>

                <div className={styles.imageList}>
                    {imageList.map((item, index) => {
                        return (

                            <div className={styles.image} >
                                <i className="fa-light fa-circle-xmark" onClick={() => removeImage(index)}></i>
                                <img src={item.image} />
                                <div className={`${styles.progress} progress mt-2`} >
                                    <div className="progress-bar progress-bar-striped progress-bar-animated"
                                        style={{ width: `${item.precent}%` }}>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )

}

export default DeliveryImage;
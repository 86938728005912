import React, { useEffect, useRef, useState } from 'react';
import styles from './camera.module.scss'

declare var Cropper: any;
const Camera = (props: any) => {

    const context = useRef<any>();
    const player = useRef<any>();
    const canvas = useRef<any>();
    const localStream = useRef<any>();
    const [src, setSrc] = useState<string>('');
    const cropper = useRef<any>();

    useEffect(() => {

        getUserMedia();

        return () => {
            localStream?.current?.getTracks()?.forEach(track => track.stop())
        }

    }, [])

    const getUserMedia = () => {
        const constraints = {
            audio: false,
            video: {
                facingMode: 'environment'
            }
        };

        // Attach the video stream to the video element and autoplay.
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                localStream.current = stream;
                if (player.current) {
                    player.current.srcObject = stream;
                }
            });
    }

    const onCapture = () => {
        context.current = (canvas.current).getContext('2d');
        context.current.drawImage(player.current, 0, 0, canvas.current.width, canvas.current.height);
        let image_data_url = canvas.current.toDataURL('image/jpeg');
         //  props.onCapture(image_data_url);
        setSrc(image_data_url)
    }

    useEffect(() => {
        if (src) {
            doCropper();
        }
    }, [src])


    const doCropper = () => {
        const image = document.getElementById('image');
        image?.setAttribute('src', src)
        cropper.current = new Cropper(image, {
            crop(event) {
            },
        });
    }

    const getCroppedImage = () => {
        const canvas = cropper.current.getCroppedCanvas({
            width: window.screen.width,
            height: window.screen.width,
        });
        const source = canvas.toDataURL();
        props.onCapture(source);
    }

    return (
        <>

            <div className={styles.camera}>

                {!src ? (
                    <>
                        <video ref={player} id="player" autoPlay></video>
                        <button onClick={onCapture} type='button' className={`btn btn-sm ${styles.capture}`} id="capture">esir</button>
                        <canvas ref={canvas} id="canvas" width={window.screen.width} height={window.screen.height}></canvas>
                    </>

                ) : (
                    <>
                        <img id="image" src={src} />
                        <div className={styles.rotate}>
                            <button type="button" className="btn  pretty-btn" onClick={() => cropper.current.move(1, -1).rotate(-45)} >
                                <span className="docs-tooltip" >
                                    <span className="fa fa-undo-alt"></span>
                                </span>
                            </button>
                            <button type="button" className="btn pretty-btn" onClick={() => cropper.current.move(1, -1).rotate(45)}>
                                <span className="docs-tooltip">
                                    <span className="fa fa-redo-alt"></span>
                                </span>
                            </button>
                        </div>
                        <button onClick={getCroppedImage} className={`btn btn-sm ${styles.capture}`} id="capture">esir</button>
                    </>
                )}

                {src}

            </div>

        </>
    )

}

export default Camera;
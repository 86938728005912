import axios from "axios";
import Swal from 'sweetalert2'

// const axiosHttp = axios.create();

// axiosHttp.interceptors.request.use(
//     (config: any) => {
//         const token = "Your Token here"
//         return {
//             ...config,
//             // headers: {
//             //     ...(token !== null && { Authorization: `${token}` }),
//             //     ...config.headers,
//             // },
//         };
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

axios.interceptors.response.use(
    (response) => {
        if (!response.data.success && response.data.message) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons.fire({
                icon: "error",
                title: "Hata...",
                text: response.data.message,
                confirmButtonText: "Tamam",
                confirmButtonColor: "#fd7e14",
            });

        }
        return response;
    },
    (error) => {
        if (error.response?.status === 401) {
            //(`unauthorized :)`);
            localStorage.removeItem("token");
            //removeLocalStorageToken
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export default axios;
import React, { useEffect, useRef, useState } from 'react';
import styles from './group.module.scss';
import { mdiBarcode } from '@mdi/js';
import Icon from '@mdi/react';
import { batchAddOrder, getWithBarcodeNumber } from '../../../services/pre-order.service';
import { toast } from 'react-toastify';
import BarcodeScanner from '../../barcode-scanner/barcode-scanner';
import Scanner from '../../scanner/scanner';

import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../../store/actions';


const Group = () => {

    const [barcodeNumber, setBarcodeNumber] = useState<string>('');
    const [orderGroup, setOrderGroup] = useState<Array<any>>([])
    const [loader, setLoader] = useState<boolean>(false);
    const batchLoader = useRef<boolean>(false)
    const [openContent, setOpenContent] = useState<boolean>(true);
    const [scanMode, setScanMode] = useState<string>('manual');
    const [scan, setScan] = useState<boolean>(false);
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const params = useParams();
    const barcodeRef = useRef<any>();
    const dispatch = useDispatch();

    const search = async (barcode?: string) => {
        if (barcodeNumber || barcode && !batchLoader.current) {
            const inputBarcode: any = barcode ? barcode : barcodeNumber;

            setScan(false);
            setLoader(true);
            const forceAssign: boolean = params.forceAssign ? true : false;
            const res = await getWithBarcodeNumber(inputBarcode, forceAssign);
            setLoader(false)
            const order = res.data.data;
            if (order) {
                setToStrage(inputBarcode);
                addOrderGroup(order);
                audioSuccessRef.current.play();

            }
            else {
                audioErrorRef.current.play();
                toast.error(res.data.message, {
                    position: "bottom-center",
                });

            }

            setBarcodeNumber('');

            if (!batchLoader.current) {
                barcodeRef.current?.focus();
            }
            setTimeout(() => {
                if (scanMode === 'camera') {
                    setScan(true)
                }
            }, 2000)
        }
        else {
        }
    }

    const addOrderGroup = (order) => {

        const index = orderGroup.findIndex(item => item.preOrderCode === order.preOrderCode);
        if (index === -1) {
            const orders = orderGroup;
            orders.unshift(order);
            checkVisitedBarcodes(orders)
        }
        else {
            checkVisitedBarcodes(orderGroup)
        }

    }


    const checkVisitedBarcodes = (orders) => {
        let barcodeList: Array<string> = [];
        let storage: string = localStorage.getItem('barcodes') as string;
        if (storage) {
            barcodeList = JSON.parse(storage);

            orders = orders.map(order => {
                order.readed = [];
                order.preOrderBarcodes = order.preOrderBarcodes.map(preOrder => {
                    const index = barcodeList.findIndex(a => a === preOrder.no);
                    if (index > -1) {
                        preOrder.visited = true;
                        order.readed.push(preOrder);
                        allBarcodesReaded(order)
                    }

                    return preOrder;

                })

                return order;
            })


            setOrderGroup([...orders]);

        }


    }

    const allBarcodesReaded = (order) => {
        if (order.readed.length === order.preOrderBarcodes.length && !order?.done) {
            doneReadedBarcodes([order]);
        }

    }

    const doneReadedBarcodes = async (order) => {
        console.log(order)
        dispatch(showLoading())
        barcodeRef.current?.blur();
        batchLoader.current = true;
        const res = await batchAddOrder(order);
        batchLoader.current = false;
        dispatch(hideLoading())
        barcodeRef.current?.focus();
        if (res.data.success) {
            const index = orderGroup.findIndex(item => item.preOrderCode === order[0].preOrderCode);
            if (index > -1) {
                orderGroup[index].done = true;
                setOrderGroup([...orderGroup])
            }
            toast.success('İşlem Başarılı.', {
                position: "bottom-center",
            })
        }
        else {
            toast.error(res.data.message, {
                position: "bottom-center",
            });
        }
    }

    const setToStrage = (barcode) => {
        let barcodeList: Array<string> = [];
        let storage: string = localStorage.getItem('barcodes') as string;
        if (storage) {
            barcodeList = JSON.parse(storage);
            const index = barcodeList.findIndex(a => a === barcode);
            if (index === -1) {
                barcodeList.push(barcode);
            }
        }
        else {
            barcodeList.push(barcode);
        }

        localStorage.setItem('barcodes', JSON.stringify(barcodeList))

    }






    const toggle = (item: any) => {
        const index = orderGroup.findIndex(a => a.preOrderCode === item.preOrderCode);
        orderGroup[index].openContent = orderGroup[index].openContent ? !orderGroup[index].openContent : true;
        setOrderGroup([...orderGroup])
    }

    const generateOrderGroups = (readed: boolean = false) => {
        let order = orderGroup.filter(a => a.readed.length !== a.preOrderBarcodes.length);;
        if (readed) {
            order = orderGroup.filter(a => a.readed.length === a.preOrderBarcodes.length);
        }

        return order.map((item, index) => {
            return (
                <>

                    <div key={item.preOrderCode} className={styles.list}>

                        <div className={styles.item}>

                            <div className={`${styles.title} ${!item.openContent ? styles.noBorder : null}`} onClick={e => toggle(item)}>
                                <span>seperis No: {item?.preOrderCode}</span>
                                <i className='fa fa-angle-down'></i>
                            </div>

                            {item.openContent ? (
                                <div className={styles.content} >
                                    <div className={styles.barcodeLength}>
                                        Okutulan / Toplam Barkod: {item.readed?.length}/{item?.preOrderBarcodes?.length}
                                    </div>


                                    {item?.preOrderBarcodes?.map(barcode => {
                                        return (
                                            <div key={barcode.no} className={`${styles.items} ${barcode?.visited ? 'text-success' : 'text-danger'}`}>
                                                {barcode.no}
                                            </div>
                                        )
                                    })}

                                    <div className={styles.info}>
                                        <div className={styles.Key}> Gönderen</div>
                                        <div className={styles.Value}> {item?.senderCustomerName} </div>
                                    </div>

                                    <div className={styles.info}>
                                        <div className={styles.Key}> Gönderen Adresi</div>
                                        <div className={styles.Value}> {item?.senderCustomerFriendlyAddress} </div>
                                    </div>

                                    <div className={styles.info}>
                                        <div className={styles.Key}> Alıcı</div>
                                        <div className={styles.Value}> {item?.receiverCustomerName} </div>
                                    </div>

                                    <div className={styles.info}>
                                        <div className={styles.Key}>  Alıcı Adresi</div>
                                        <div className={styles.Value}> {item?.receiverCustomerFriendlyAddress} </div>
                                    </div>

                                </div>
                            ) : null}



                        </div>

                    </div>


                </>

            )
        })

    }

    const onScannerSuccess = (barcode: string) => {
        setBarcodeNumber(barcode);
        search(barcode);
        setScan(false);
    }

    const getReadingBarcodes = () => {
        let barcodesLength = 0;
        let readedBarcodesLength = 0;
        orderGroup.filter(a => a.readed.length !== a.preOrderBarcodes.length).map(item => {
            barcodesLength += item.preOrderBarcodes.length;
            readedBarcodesLength += item.preOrderBarcodes.filter(a => a.visited).length;
        });
        const data = {} as any;
        data.all = barcodesLength;
        data.visited = readedBarcodesLength;
        return data;
    }

    const getDoneBarcodes = () => {
        let barcodesLength = 0;
        orderGroup.filter(a => a.readed.length === a.preOrderBarcodes.length).map(item => {
            barcodesLength += item.preOrderBarcodes.length;
        });
        return barcodesLength;
    }


    return (
        <>

            <audio controls ref={audioSuccessRef}>
                <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
            </audio>

            <audio controls ref={audioErrorRef}>
                <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
            </audio>

            <div className={styles.group}>

                <div className={`${styles.infos} box-item`}>
                    <div className={styles.infoItems}>
                        <div className={styles.infoItem}>
                            <div className={styles.key}>Işlem yapılan Gönderi:</div>
                            <div className={styles.value}>{orderGroup.filter(a => a.readed.length !== a.preOrderBarcodes.length).length}</div>

                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.key}>Işlem yapılan barkod:</div>
                            <div className={styles.value}>{getReadingBarcodes()?.visited}/{getReadingBarcodes()?.all}</div>
                        </div>
                    </div>
                    <div className={styles.infoItems}>
                        <div className={styles.infoItem}>
                            <div className={styles.key}>Gönderisi oluşturulanlar:</div>
                            <div className={styles.value}>{orderGroup.filter(a => a.readed.length === a.preOrderBarcodes.length).length}</div>
                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.key}>Gönderisi oluşturuldi barkod :</div>
                            <div className={styles.value}>{getDoneBarcodes()}</div>
                        </div>
                    </div>
                </div>

                <div className={styles.barcode}>
                    <input
                        readOnly={loader}
                        ref={barcodeRef}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                search();
                            }
                        }}
                        value={barcodeNumber} onChange={(e: any) => setBarcodeNumber(e.target.value)} type="text" placeholder='Barkod No Giriniz' />
                    <button onClick={() => search()} className={`${styles.ara} pretty-btn`} type='button'>Ara2</button>
                    <button onClick={() => { setScan(!scan); setScanMode('camera') }} className={`${styles.barcodeImage} btn btn-outline`} type='button'>
                        <Icon path={mdiBarcode}
                            title={''}
                            size={1}
                            horizontal
                            vertical
                            rotate={180}
                            color={'black'}
                        // spin
                        />
                    </button>


                </div>





                {scan ? (
                    <div className={styles.video}>
                        <Scanner onClose={() => { setScan(false); setScanMode('manaul') }} scan={scan} onData={(e) => { onScannerSuccess(e); setScanMode('camera') }} />
                    </div>
                ) : null}


                {loader ? (
                    <div className={styles.loader}>
                        <div className='spinner-border text-danger'></div>
                    </div>
                ) : null}



                {orderGroup.filter(a => a.readed.length !== a.preOrderBarcodes.length).length > 0 ? (
                    <div className={styles.groupSection}>

                        <div className={styles.groupTitle}>
                            İşlem yapılanlar
                        </div>
                        <div className={styles.part}>

                            {generateOrderGroups()}
                        </div>
                    </div>
                ) : null}

                {orderGroup.filter(a => a.readed.length === a.preOrderBarcodes.length).length > 0 ? (
                    <>

                        <div className={styles.groupSection}>
                            <div className={styles.groupTitle}>
                                Gönderisi oluşturulanlar
                            </div>
                            <div className={styles.part}>

                                {generateOrderGroups(true)}

                            </div>
                        </div>

                    </>
                ) : null}


            </div >

        </>
    )

}

export default Group;
import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
// /* @ts-expect-error Server Component */
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import styles from './barcode.module.scss';

declare var ZXing: any;
const Barcode = (props: any) => {
  const [data, setData] = useState('No result');


  useEffect(() => {


    // const barcodeDetector = new BarcodeDetector({
    //   formats: ["code_39", "codabar", "ean_13"],
    // });



    setTimeout(() => {


      let selectedDeviceId: any;
      // const formats = [
      //   ZXing.BarcodeFormat.QR_CODE,
      //   ZXing.BarcodeFormat.DATA_MATRIX,
      //   ZXing.BarcodeFormat.DATA_MATRIX,
      //   ZXing.BarcodeFormat.AZTEC,
      //   ZXing.BarcodeFormat.CODE_39,
      //   ZXing.BarcodeFormat.CODE_128,
      //   ZXing.BarcodeFormat.CODE_93,
      //   ZXing.BarcodeFormat.CODABAR,
      //   ZXing.BarcodeFormat.ITF,
      //   ZXing.BarcodeFormat.RSS_14,
      //   ZXing.BarcodeFormat.UPC_A,
      //   ZXing.BarcodeFormat.UPC_E,
      //   ZXing.BarcodeFormat.EAN_8,
      //   ZXing.BarcodeFormat.EAN_13,


      // ];

      const hints = new Map();
      const enabledFormats = [
        // ...ALL_FORMATS_WHICH_YOU_WANT_TO_ENABLE
        ZXing.BarcodeFormat.UPC_A,
        ZXing.BarcodeFormat.QR_CODE,
        ZXing.BarcodeFormat.DATA_MATRIX,
        ZXing.BarcodeFormat.DATA_MATRIX,
        ZXing.BarcodeFormat.AZTEC,
        ZXing.BarcodeFormat.CODE_39,
        ZXing.BarcodeFormat.CODE_128,
        ZXing.BarcodeFormat.CODE_93,
        ZXing.BarcodeFormat.CODABAR,
        ZXing.BarcodeFormat.ITF,
        ZXing.BarcodeFormat.RSS_14,
        ZXing.BarcodeFormat.RSS_EXPANDED,
        ZXing.BarcodeFormat.UPC_EAN_EXTENSION,
        ZXing.BarcodeFormat.UPC_E,
        ZXing.BarcodeFormat.EAN_8,
        ZXing.BarcodeFormat.EAN_13,
      ];
      hints.set(ZXing.DecodeHintType.POSSIBLE_FORMATS, enabledFormats);

      //const codeReader = new ZXing.BrowserMultiFormatReader(hints)
      const codeReader = new ZXing.BrowserMultiFormatReader()

      console.log('ZXing code reader initialized')
      codeReader.listVideoInputDevices()
        .then((videoInputDevices: any) => {

          // (document.getElementById('startButton') as HTMLButtonElement).addEventListener('click', () => {
          codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (result: any, err: any) => {
            if (result) {
              setData(result.text)
              //   props.onDate(result.text);
            }
            if (err) {
              // document.getElementById('result').textContent = err
            }
          })
          console.log(`Started continous decode from camera with id ${selectedDeviceId}`)
          // })

          // document.getElementById('resetButton').addEventListener('click', () => {
          //   codeReader.reset()
          //   document.getElementById('result').textContent = '';
          //   console.log('Reset.')
          // })

        })
        .catch((err: any) => {
          // console.error(err)
        })





    }, 2000);



    // setTimeout(() => {

    //   Quagga.init({
    //     inputStream: {
    //       name: "Live",
    //       type: "LiveStream",
    //       target: document.querySelector('#vide')    // Or '#yourElement' (optional)
    //     },
    //     decoder: {
    //       readers: [
    //         "codabar_reader",
    //         "code_128_reader",
    //         "ean_reader",
    //         "ean_8_reader",
    //         "code_39_reader",
    //         "code_39_vin_reader",
    //         "upc_reader",
    //         "upc_e_reader",
    //         "i2of5_reader",
    //         "2of5_reader",
    //         "code_93_reader",
    //       ],
    //       debug: {
    //         drawBoundingBox: false,
    //         showFrequency: false,
    //         drawScanline: false,
    //         showPattern: false
    //       },
    //       multiple: false
    //     }
    //   }, function (err) {
    //     if (err) {
    //       //   console.log(err);
    //       return
    //     }
    //     console.log("Initialization finished. Ready to start");
    //     Quagga.start();
    //     Quagga.onDetected((data) => {
    //       console.log(data)
    //     })

    //   });


    // }, 1000)

  }, [])

  return (
    <>

      <video id="video" className={styles.qrCode} width="300" height="200" autoPlay={true} muted={true} playsInline={true}></video>

      <button id='startButton' type='button'>start</button>

      <div>{data}</div>



      {/* <video id="video" autoPlay={true} playsInline={true}></video> */}
      {/* <QrReader
        onResult={(result: any, error) => {
          if (!!result) {
            console.log(result)
            setData(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        constraints={{ facingMode: 'user' }}
        className={styles.qrCode}
      // style={{ width: '100%' }}
      />
      <p>{data}</p>

      <BarcodeScannerComponent
        width={500}
        height={500}

        // videoConstraints={{}}
        // stopStream={false}
        onUpdate={(err, result: any) => {
          if (result) setData(result.text);
          else setData("Not Found");
        }}
        onError={() => { }}
      />
      <p>{data}</p> */}

      {/* <BarcodeScannerComponent
        width={500}
        height={500}
        torch={false}
        // videoConstraints={{}}
        // stopStream={false}
        onUpdate={(err, result: any) => {
          if (result) setData(result.text);
          else setData("Not Found");
        }}
        onError={(e) => { console.log(e) }}
      />
      <p>{data}</p> */}

    </>
  );
};

export default Barcode;
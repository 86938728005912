import { createHeaders, settings } from "../common/utils";
import axios from "axios";
import { IApiBaseResult } from "../interfaces/api-base-result.interface";
import { IDistributionList } from "../interfaces/distribution-list.interface";
import { IDistributionFilter } from "../interfaces/distribution-filter.interface";
import moment from "moment";
import { IDegreeOfRelavity } from "../interfaces/degree-of-relavity.interface";
import { IDelivery } from "../interfaces/delivery-params.interface";
import { IReturnParams } from "../interfaces/return-params.interface";

export const getDistributionPlansFilter = async (
    params?: IDistributionFilter
): Promise<IApiBaseResult<IDistributionList[]>> => {
    console.log(params)
    let url: string =
        settings().baseUrl + "DistributionPlan/GetMyDistributionPlans";
    const date = new Date();
    let data = {
        orderNumber: params?.orderNumber,
        statuses: params?.statuses,
        orderCreatedStartDate: params?.orderCreatedStartDate
            ? moment(params?.orderCreatedStartDate).format("YYYY-MM-DD")
            : moment(date, "YYYY-MM-DD").subtract(10, 'years').format("YYYY-MM-DD"),
        orderCreatedEndDate: params?.orderCreatedEndDate
            ? moment(params?.orderCreatedEndDate).format("YYYY-MM-DD")
            : moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
    };

    let headers = await createHeaders();
    const res = await axios.post(url, data, { headers: headers });
    return res.data;
};

export const getDistributionPlan = async (
    barcodeNo: string
): Promise<IApiBaseResult<IDistributionList[]>> => {
    let url: string =
        settings().baseUrl + "DistributionPlan/GetMyDistributionPlans";

    let headers = await createHeaders();
    let data = !barcodeNo ? {} : { barcodeNo };
    let response = await axios.post(url, data, { headers: headers });
    return response.data;
};

export const getDegreeOfRelavity = async (): Promise<
    IApiBaseResult<IDegreeOfRelavity[]>
> => {
    let url: string =
        settings().baseUrl + "DegreeOfRelativity/GetAllDegreeOfRelativity";
    let headers = await createHeaders();
    let data = {};
    let response = await axios.post(url, data, { headers: headers });
    return response.data;
};

export const orderDeliveryApi = async (
    params: IDelivery[]
): Promise<IApiBaseResult<IDelivery[]>> => {
    let url: string = settings().serviceUrl + 'delivery/batch';

    //let url: string = "https://879b6b0q-7006.euw.devtunnels.ms/api/delivery/batch";
    let headers = await createHeaders();
    const data = { deliveries: params };
    let response = await axios.post(url, data, {
        headers: headers,
    });
    return response.data;
};

export const getReasonForReturn2 = async () => {
    let url: string = settings().baseUrl + "ReturnReason/GetAllReturnReason";
    let headers = await createHeaders();
    let data = {};
    let response = await axios.post(url, data, { headers: headers });
    return response.data;
};

export const orderReturnApi = async (
    params: IReturnParams
): Promise<IApiBaseResult<IReturnParams[]>> => {
    let url: string = settings().baseUrl + "OrderReturn/AddFromBody";
    let headers = await createHeaders();
    const data = {
        barcodeIds: ["2a09cded-1357-459a-e177-08db9b3e406d"],
        contactlessDeliveryCode: "dsdsd",
        description: "dsdsd",
        distributionPlanId: "3269dd30-689d-4ed6-014f-08db9b3e3ba2",
        orderID: "f5f83fec-ade3-4157-601f-08db9b3e39f3",
        returnReasonId: "039d158e-a03d-43f7-1f00-08d9df47d23c",
    };
    let response = await axios.post(url, params, {
        headers: headers,
    });
    return response.data;
};

export const handOver = async (distributionPlanId: string): Promise<any> => {
    let url: string =
        settings().baseUrl +
        `DistributionPlan/HandOver?distributionPlanId=${distributionPlanId}`;
    let headers = await createHeaders();
    let response = await axios.post(url, {}, { headers: headers });
    return response.data;
};

export const unassignPreOrder = async (
    collectionPlanId: string,
    reason: string
  ): Promise<any> => {
    let url: string =
      settings().baseUrl +
      `CollectionPlan/RemoveAssignment?reason=${reason}&id=${collectionPlanId}`;
    let headers = await createHeaders();
    let response = await axios.post(url, {}, { headers: headers });
    return response.data;
  };
  
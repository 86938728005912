import React, { useEffect, useState } from 'react';
import styles from './distributions-filters.module.scss';
import { IDistributionFilter } from '../../../interfaces/distribution-filter.interface';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';
registerLocale('tr', tr)

const DistributionFilters = (props: any) => {

    const [openContent, setOpenContent] = useState<boolean>(false);
    const [orderNumber, setOrderNumber] = useState<string>();
    const [orderCreatedStartDate, setOrderCreatedStartDate] = useState<Date>(new Date());
    const [orderCreatedEndDate, setOrderCreatedEndDate] = useState<Date>(new Date());
    const [status, setStatus] = useState<string>('');


    useEffect(() => {
        const startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 10)
        setOrderCreatedStartDate(startDate)
    }, [])



    const filter = () => {
        const data = {} as IDistributionFilter;
        data.orderNumber = (orderNumber);
        data.orderCreatedStartDate = orderCreatedStartDate;
        data.orderCreatedEndDate = orderCreatedEndDate;
        data.status = Number(status);
        data.statuses = [Number(status)];
        props.onFilter(data);
    }



    const reset = () => {
        const startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 10)
        const endDate = new Date();
        setOrderNumber('')
        setOrderCreatedStartDate(startDate)
        setOrderCreatedEndDate(endDate)
        setStatus('');

        ///send filter data
        const data = {} as IDistributionFilter;
        data.orderNumber = (orderNumber);
        data.orderCreatedStartDate = startDate;
        data.orderCreatedEndDate = endDate;
        data.status = Number(status);
        data.statuses = [Number(status)];
        props.onFilter(data);

    }

    return (
        <>

            <div className={styles.filters}>
                <div className={styles.filterTitle} onClick={() => setOpenContent(!openContent)} >
                    <div className={styles.titleLeft}>
                        <i className='fa fa-filter fa-lg'></i>
                        <span>Filtreyi Kapat</span>
                    </div>
                    <i className={`fa fa-angle-${openContent ? 'up' : 'down'} fa-lg ${styles.toggleIcon}`}></i>
                </div>

                <div className={`${styles.filterContent} ${openContent ? '' : styles.hide}`}>
                    <div className={styles.filterItem}>
                        <input type='text' value={orderNumber} onChange={(e: any) => setOrderNumber(e.target.value)} placeholder='Gönderi No' className='form-control' />
                    </div>

                    <div className={styles.filterItem}>

                        <select className='form-control' value={status} onChange={(e: any) => setStatus(e.target.value)}>
                            <option value={""}>Tüm Durumlar</option>
                            <option value={"16"}>Teslim Edildi</option>
                            <option value={"18"}>Bekleyen İade</option>
                        </select>
                    </div>

                    <div className={styles.filterItem}>

                        <div className={styles.datePicker}>
                            <DatePicker
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                scrollableYearDropdown={true}
                                selected={orderCreatedStartDate} onChange={(date) => setOrderCreatedStartDate(date)}
                                locale="tr"
                            />

                        </div>

                        <div className={styles.datePicker}>
                            <DatePicker
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                scrollableYearDropdown={true}
                                selected={orderCreatedEndDate} onChange={(date) => setOrderCreatedEndDate(date)}
                                locale="tr"
                            />

                        </div>


                    </div>

                    <div className={styles.buttons}>
                        <button className='pretty-btn' onClick={reset} >Temizle</button>
                        <button className='pretty-btn btn-outline' onClick={filter}>Filtrele</button>
                    </div>


                </div>

            </div>


        </>
    )

}

export default DistributionFilters;

import axios from './../common/axios';
import { createHeaders, settings } from '../common/utils';
import { IUser } from '../interfaces/user.interface';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './../common/firebase';

export const loginService = async (body: IUser) => {
    let messaging :any;
    const url = settings().authUrl + '';
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    //  let fcmToken = 'fsdfsdf';
    //    let params = 'username=' + body.username + '&password=' + body.password + '&fcmToken=' + fcmToken + '&grant_type=password&scope=&client_id=';
    let params = {} as any;
    params.username = body.username;
    params.password = body.password;
    let permission = await Notification.requestPermission();
    const support = await isSupported();
    //  alert(support);
    if (permission == "granted") {
        const fireBaseApp = initializeApp(firebaseConfig);
        messaging = getMessaging(fireBaseApp);
        params.fcmToken = await getToken(messaging, { vapidKey: 'BJEm7XlyQsm4JbdHkPfDx8gkAtrEdfL8zwMrkGKIl7qwymBbXYxt3Deygwtl_6I_DKp5iy9rHLLeZNdhIlP6TYM', });
    }
    else {
        alert('not supported fcm')
    }

    params.grant_type = 'password';

    const res= await axios.post(url, params, {
        headers
    });

    return {res, messaging}
}


export const getCarrierAssignment = async (): Promise<any> => {
    let url: string = settings().serviceUrl + "carrier/assignment-summery";
    let headers = await createHeaders();
    const res = await axios.get(url, { headers: headers });
    return res.data;
}


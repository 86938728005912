
export const firebaseConfig = {
    apiKey: "AIzaSyCqDLav3m2B1jZMTQEC-p_5JQK9mihf8ds",
    authDomain: "igckargo-4fa09.firebaseapp.com",
    projectId: "igckargo-4fa09",
    storageBucket: "igckargo-4fa09.appspot.com",
    messagingSenderId: "427953995227",
    appId: "1:427953995227:web:33d8d373825ee0256e3bfe",
    measurementId: "G-EXND57HD5B"
};
